import React, { useState } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "./../../../../../Styles/non-module-styles/styles.css";
import "./login-form.css";

import loginImage from "./../../../../../Assets/Images/common/lunch-svgrepo-com.png";

function LoginForm() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    function logIn(event) {
        event.preventDefault();

        if (validFields()) {
            const loginData = {
                username: username,
                password: password,
                returnUrl: function () {
                    let returnUrl = "";
                    const urlParams = new URLSearchParams(window.location.search);
                    const foundReturnUrl = urlParams.get("ReturnUrl");
                    if (foundReturnUrl != null)
                        returnUrl = foundReturnUrl;

                    return returnUrl;
                }()
            };

            fetch("/account/login", {
                method: "POST",
                body: JSON.stringify(loginData),
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "dataType": "json"
                }
            })
            .then(response => response.json())
            .then(json => {
                if (json.user)
                    window.location.href = json.returnUrl;
            })
            .catch(error => console.log(error));
        }
        else {
        }
    }

    function validFields() {
        let isValid = true;

        let error = "";
        if (username.trim() === "") {
            error = "Please enter your username.";
            isValid = false;
        }
        else if (password.trim() === "") {
            error = "Please enter your password.";
            isValid = false;
        }

        return isValid;
    }

    return (
        <div className="login_banner_area">
            <div className="container logo-area">
                <div className="column">
                    <h1 className="text-style-1">CanteenCenter</h1>
                    <br />
                    <div className="logo">
                        <img src={loginImage} alt="" />
                    </div>
                </div>


            </div>
            <div className="container ">
                <div className="content-section ">
                    <div className="card login-card">
                        <div className="card login-page">
                            <h1 className="text-style-1">CanteenCenter</h1>
                            <i className="ph-fork-knife-fill mb-1"></i>
                            <h3 className="text-style-3">Welcome! </h3>
                            <p className="p-style">Login to manage your school's canteen.</p>
                            <br />
                            <div className="mb-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="username_login"
                                    placeholder="Username"
                                    aria-describedby="username_login"
                                    name="username"
                                    value={username}
                                    onChange={e => setUsername(e.target.value)}
                                    onKeyDown={(e) => { if (e.key === "Enter") logIn(e) }}
                                    required
                                    autoFocus
                                />
                            </div>
                            <div className="mb-3">
                                <input
                                    type="password"
                                    className="form-control"
                                    id="password_login"
                                    placeholder="Password"
                                    aria-describedby="password_login"
                                    name="password"
                                    value={password}
                                    onChange={e => setPassword(e.target.value)}
                                    onKeyDown={(e) => { if (e.key === "Enter") logIn(e) }}
                                    required
                                />
                            </div>
                            <br />
                            <a className="primary-btn" onClick={logIn}>LOGIN</a>
                            <a href="/account/forgot" className="link-color" style={{ cursor: "pointer" }}>Forgot username or password?</a>

                        </div>
                    </div>


                </div>
            </div>

        </div>
    );
}

export default LoginForm